<template>
  <div class="app-container">
    <ShowTitle text="隱私設定和帳號安全" type="title" />

    <el-row>
      <el-col :span="7">
        <div class="left-menu">
          <ProfileMenu />
        </div>
      </el-col>
      <el-col :span="15">
        <el-form label-width="100px" label-position="top"> </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import mainCommon from "@/utils/mainCommon";
import configCommon from "@/utils/configCommon";
// import { userProfile, userEdit } from "@/api/user";
// import { userAvatarChange } from "@/api/user";
// import Cookies from "js-cookie";

export default {
  mixins: [mainCommon],
  data() {
    return {
      // imageUrl: null,
      // action: `${configCommon.apiHost}/api/upload/image`,
      // submitLoading: false,
      // dataObj: {},
    };
  },
  created() {},
  methods: {
    HandleSubmit() {
      this.submitLoading = true;
      userEdit(this.dataObj)
        .then((res) => {
          this.pushNotification(res.message);
          this.getList();
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.left-menu {
  top: 10px;
  position: sticky;
}
/deep/ .el-form-item__label {
  padding: 0 0 20px;
  line-height: unset;
}
/deep/ .el-input {
  width: 300px;
}
/deep/ .el-input__inner {
  border-radius: 0;
  padding: 0;
  border: 0;
  width: 300px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
}
.submit-btn {
  padding: 9px 11px;
  user-select: none;
  font-size: 14px;
  appearance: none;
  background-color: #0095f6;
  border-radius: 4px;
  color: #fff;
  border: 0;
  &:disabled {
    opacity: 0.3;
  }
}
</style>